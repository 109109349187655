@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import 'header.scss';
@import 'footer.scss';
@import 'home.scss';
@import 'sobre.scss';
@import 'servico.scss';
@import 'contato.scss';


* {
   margin: 0;
   padding: 0;
   outline: 0;
   box-sizing: border-box;
}

body,
html {
   height: 100%;
   font-family: 'Roboto', serif;
}
a {
   text-decoration: none;
}
li {
   list-style: none;
}
button {
   cursor: pointer;
}
.container {
   width: 1200px;
   padding: 0 1%;
   display: flex;
   justify-content: space-between;
   user-select: none;
}

.notfound {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;

   .container {
      justify-content: center;
      flex-direction: column;
      align-items: center;
   }

   h1 {
      font-size: 4rem;
   }
   h2 {
      font-size: 1.5rem;
      font-weight: 400;
   }
}

