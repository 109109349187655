@import "colors.scss";

.callContact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    margin-top: 50px;
    background-image: url("../img/image-alt3.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        left: 0;
        right: 0;
        z-index: 1;
    }

    h2 {
        z-index: 2;
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: $whiteColor;
        text-shadow: 1px 1px 2px black;
        text-transform: uppercase;
    }
}

.cardsContact {
    padding: 70px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        flex-direction: column;
        align-items: center;
        user-select: text;
    }
    .boxContact {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        transition: all ease 0.4s;

        &:hover {
            transform: scale(1.1);
        }
        .boxContactWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 300px;
            padding: 2%;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
            border-radius: 10px;

            .iconContact {
                text-align: center;
                width: 100%;
                margin-bottom: 10px;

                .material-icons {
                    padding: 10px;
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
                    border-radius: 50%;
                    font-size: 2rem;
                    color: $whiteColor;
                    background-color: $primaryColor;
                    transition: all ease 0.4s;
                }
            }
            .titleContact {
                font-size: 2rem;
                margin: 10px 0;
                color: $primaryText;
            }
            .descriptionContact {
                font-size: 1rem;
                color: $secondText;
                font-style: italic;
                margin: 5px 0;
                span {
                    font-weight: bold;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .callContact {
        h2 {
            font-size: 2rem;
        }
    }
    .cardsContact {
        .boxContact {
            .boxContactWrapper {
                .titleContact {
                    font-size: 1.6rem;
                }
                .descriptionContact {
                    font-size: 0.9rem;
                }
            }
        }
    }
}
