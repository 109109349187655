$primaryText: #4b4b4b;
$secondText: #8e94a0;

$primaryColor: #8e44ad;
$secondColor: #9b59b6;
$transparent: rgba(141, 68, 173, 0.815);
$whiteColor: #fff;

$errorColor: #e74c3c;
$successColor: #27ae60;
$alertColor: #f39c12;
