@import "colors.scss";

.callService {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    margin-top: 50px;
    background-image: url("../img/image-alt1.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        left: 0;
        right: 0;
        z-index: 1;
    }

    h2 {
        z-index: 2;
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: $whiteColor;
        text-shadow: 1px 1px 2px black;
        text-transform: uppercase;
    }
}

.features {
    padding: 70px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primaryText;

    .container {
        flex-direction: column;
        align-items: center;
    }

    .featuresBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        align-items: center;

        .featuresCardWrapper {
            padding: 1%;
            margin: 10px 5px;
            text-align: center;

            &:hover {
                .cardWrapperIcon > .material-icons {
                    transform: scale(1.2);
                    color: $primaryColor;
                    background-color: $whiteColor;
                }
            }

            .cardWrapperIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                .material-icons {
                    padding: 10px;
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
                    border-radius: 50%;
                    font-size: 2rem;
                    color: $whiteColor;
                    background-color: $primaryColor;
                    transition: all ease 0.4s;
                }
            }
            .cardWrapperTitle {
                font-size: 1.3rem;
                font-weight: 500;
                color: $primaryText;
                margin-bottom: 10px;
            }
            .cardWrapperDesc {
                font-size: 1rem;
                font-weight: 400;
                color: $secondText;
            }
        }
    }
}

.checkService {
    padding: 70px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #ddd;

    .container {
        align-items: center;
        flex-direction: column;
    }

    .title {
        font-size: 2.5rem;
        color: $primaryText;
        font-weight: 400;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background-color: #fff;
            position: absolute;
            border-radius: 5px;
            bottom: 5px;
            z-index: 1;
        }
    }

    .boxServiceCards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .boxService {
            display: flex;
            flex-direction: column;
            position: relative;
            cursor: pointer;
            margin: 10px;
            height: 100%;

            h2 {
                font-size: 1.2rem;
                font-weight: 400;
                text-align: center;
            }

            .imgService {
                overflow: hidden;
                border-radius: 10px;
                width: 300px;
                img {
                    border-radius: 10px;
                    width: 100%;
                    object-fit: cover;
                    transition: all ease 0.4s;
                    filter: opacity(70%);

                    &:hover {
                        transform: scale(1.2);
                        filter: opacity(100%);
                        -webkit-filter: opacity(100%);
                    }
                }
            }
        }
    }
}

.partners {
    padding: 70px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .container {
        align-items: center;
        flex-direction: column;
    }

    .title {
        font-size: 2.5rem;
        color: $primaryText;
        font-weight: 400;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background-color: #ddd;
            position: absolute;
            border-radius: 5px;
            bottom: 5px;
            z-index: -1;
        }
    }
    .boxPartners {
        display: flex;
        align-items: center;
        .boxPartnersWrapper {
            width: 150px;
            margin: 10px 15px;
            img {
                width: 100%;
            }
        }
    }
}

.modal {
    width: 100%;
    height: 100vh;
    padding: 20px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;

    .modal_container {
        transition: all ease 0.4s;
        z-index: 3;
        background: $whiteColor;
        display: grid;
        align-items: end;
        gap: 30px;
        border-radius: 10px;
        animation: animeModal forwards 0.4s;

        .modal_img {
            margin: 50px 0;
            grid-column: 1;
            padding-left: 5px;
            display: flex;
            justify-content: center;
            img {
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
                max-width: 500px;
            }
        }
        .modal_data {
            max-width: 400px;
            grid-column: 2;
            margin: 50px 0;
            padding-right: 5px;
            color: $secondText;

            .modal_btn_close {
                border-radius: 50%;
                border: 2px solid $primaryText;
                width: 35px;
                height: 35px;
                position: absolute;
                top: -10px;
                right: -10px;
                background-color: $whiteColor;
                color: $primaryText;
                text-align: center;
                font-size: 1.5rem;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
                cursor: pointer;
            }
            span {
                margin: 10px 0;
                font-size: 0.9rem;
            }
            h2 {
                margin: 10px 0;
                color: $primaryText;
            }
            p {
                margin: 15px 0;
            }
            a {
                text-decoration: none;
                color: $primaryText;
            }
        }
    }
}

@keyframes animeModal {
    from {
        opacity: 0;
        transform: translate3d(-30px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@media (max-width: 768px) {
    .callService {
        h2 {
            z-index: 2;
            font-size: 2rem;
        }
    }
    .features {
        .featuresBox {
            grid-template-columns: 1fr;
            .featuresCardWrapper {
                width: 100%;

                .cardWrapperIcon {
                    .material-icons {
                        font-size: 1.5rem;
                    }
                }
                .cardWrapperTitle {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .checkService {
        .title {
            font-size: 2rem;
        }

        .boxServiceCards {
            .boxService {
                .imgService {
                    width: 80%;
                    height: 200px;
                    align-self: center;
                }
            }
        }
    }
    .partners {
        .title {
            font-size: 2rem;
        }
        .boxPartners {
            .boxPartnersWrapper {
                width: 120px;
                margin: 10px 15px;
                img {
                    width: 100%;
                }
            }
        }
    }
    .modal {
        .modal_container {
            width: 80%;
            margin-top: 60px;
            .modal_img {
                margin: 50px 0 10px 0;
                padding: 0 5px;
                grid-column: 1;

                img {
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
                    max-width: 90%;
                }
            }
            .modal_data {
                max-width: 100%;
                grid-column: 1;
                margin: 0;
                padding: 15px;

                h2 {
                    font-size: 1.3rem;
                }
                p {
                    font-size: 0.9rem;
                }
                a {
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@media (max-width: 620px) {
    .modal {
        .modal_container {
            width: 100%;
            .modal_img {
                img {
                    max-width: 80%;
                }
            }
        }
    }
}
