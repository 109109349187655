@import 'colors.scss';

.topo {
   height: 60px;
   width: 100%;
   background-color: $primaryColor;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: 0;
   z-index: 5;
   transition: all 0.4s;

   .logo {
      width: 200px;
      img {
         width: 100%;
         cursor: pointer;
      }
   }
   .menu {
      display: flex;
      align-items: center;
      .menu-nav ul {
         display: flex;
         list-style-type: none;

         li {
            a {
               color: $whiteColor;
               font-weight: 500;
               font-size: 1rem;
               padding: 10px;
               text-transform: uppercase;
               transition: all ease 0.4s;

               &:hover,
               &.active {
                  opacity: 0.5;
               }
            }
         }
      }
   }
}
//colocar quando ativar o scroll
.activeMenuTop {
   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 999;
   background-color: $transparent;
   transition: all 0.4s;
}

@media (max-width: 768px) {
   .menuMobile-btn {
      display: block;
      width: 30px;
      height: 30px;
      color: $whiteColor;
      border-top: 4px solid;
      float: right;
      position: relative;
      cursor: pointer;
      backface-visibility: hidden;
      &::after,
      &::before {
         content: '';
         display: block;
         height: 4px;
         margin-top: 6px;
         background-color: currentColor;
         position: relative;
         transition: transform 0.2s ease;
      }
   }
   .menuMobile-btn.active {
      border-top-color: transparent;
      &::after {
         margin-top: 0;
         top: 2px;
         transform: rotate(45deg);
      }
      &::before {
         margin-top: 0;
         top: 6px;
         transform: rotate(135deg);
      }
   }
   .menu-nav.mobile-menu {
      background-color: $transparent;
      position: fixed;
      padding: 8px;
      right: 0;
      top: 60px;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(60px, 0, 0);
      transition: 0.3s;
      ul {
         flex-direction: column;

         li {
            display: block;
            margin: 10px 0;

            a {
               padding: 10px 0;
               width: 100%;
               text-align: center;
            }
         }
      }
   }

   .menu-nav.mobile-menu.active {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      z-index: 999;
   }
}
