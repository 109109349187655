@import "colors.scss";

.callabout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    margin-top: 50px;
    background-image: url("../img/image-alt2.png");

    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        left: 0;
        right: 0;
        z-index: 1;
    }

    h2 {
        z-index: 2;
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: $whiteColor;
        text-shadow: 1px 1px 2px black;
        text-transform: uppercase;
    }
}

.about {
    padding: 40px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    color: $primaryText;

    .container {
        flex-direction: column;
        align-items: center;
    }

    .callskill {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) 2fr;
        gap: 15px;
        padding: 40px 1%;

        .photo-perfil {
            img {
                border-radius: 10px;
                max-width: 100%;
            }
        }
        .descriptioncall {
            .title {
                font-size: 1.6rem;
            }
            .description {
                font-size: .9rem;
                line-height: 1.25rem;
                font-weight: 400;
                color: $secondText;
                margin: 10px 0;
            }
            .skill-boxes {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                max-width: 800px;
                .box {
                    margin: 8px;

                    .title-box {
                        text-align: center;
                        font-size: .9rem;
                        margin-top: 5px;
                    }
                    .box-image {
                        width: 55px;
                        margin: 5px auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-width: 100%;
                            transition: .4s ease all;
                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}
.experience {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    .container {
        flex-direction: column;
        align-items: center;
    }
    .title {
        margin: 10px 0;
        font-size: 1.6rem;
        font-weight: 500;
        color: $primaryText;
    }

    .experience-boxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        .box {
            margin: 5px;
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 10px;
            box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.322);
            .date {
                font-size: 0.8rem;
                color: $primaryText;
            }
            .title-box {
                font-size: 1rem;
                color: $primaryText;
                margin: 5px 0;
            }
            .description {
                color: $secondText;
                font-size: 0.9rem;
            }
        }
    }
}
.linksmedia {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    .container {
        flex-direction: column;
        align-items: center;
    }
    .title {
        margin: 10px 0;
        font-size: 1.6rem;
        font-weight: 500;
        color: $primaryText;
    }
    .linksmedia-boxes {
        display: flex;
        .box {
            margin: 10px;
            .title-box {
                font-size: 1rem;
                font-weight: 400;
                color: $primaryText;
                text-align: center;
            }
            a {
                text-decoration: none;
                display: block;
                img {
                    width: 60px;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .about {
        .callskill {
            grid-template-columns: 1fr;
            .photo-perfil {
                display: none;
            }
            .descriptioncall {
                .title {
                    text-align: center;
                }
            }
        }
    }
    .experience {
        .experience-boxes {
            grid-template-columns: 1fr;
        }
    }
}
