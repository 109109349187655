@import "colors.scss";

.bannerTop {
    margin-top: 60px;
}

.banner {
    padding: 30px 0;
    background: linear-gradient(to bottom, $primaryColor, $secondColor);
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    .bannerCall {
        width: 40%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $whiteColor;

        h2 {
            text-transform: uppercase;
            font-size: 2.5rem;
            margin-bottom: 10px;
            font-weight: 600;
        }
    }
    .bannerImg {
        width: 60%;
        padding-left: 20px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.banner-final {
    width: 100%;
    img {
        background: $secondColor;
        width: 100%;
    }
}

.features {
    padding: 20px 0 40px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primaryText;

    h2 {
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 25px;
        text-transform: uppercase;
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background-color: #ddd;
            position: absolute;
            border-radius: 5px;
            bottom: 5px;
            z-index: -1;
        }
    }
    .container {
        flex-direction: column;
        align-items: center;
    }

    .featuresCall {
        display: flex;
        align-items: center;
        flex-direction: column;

        .featuresImg {
            margin: 10px 0;
            width: 60%;
            padding-bottom: 50px;
            img {
                width: 100%;
            }
        }
    }
}

.callAbout {
    padding: 70px 0;
    width: 100%;
    color: $primaryText;
    background-color: #eee;
    display: flex;
    justify-content: center;

    .callAboutText {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-right: 10px;
        h2 {
            font-size: 2.5rem;
            font-weight: 500;
            text-transform: uppercase;
        }
        p {
            margin: 10px 0;
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 400;
            color: $secondText;
        }
        a {
            text-decoration: none;
            padding: 15px 25px;
            border-radius: 20px;
            text-align: center;
            margin: 10px 0;
            border: 1px solid $primaryColor;
            background-color: $primaryColor;
            color: $whiteColor;
            font-size: 1rem;
            font-weight: 400;
            transition: all ease 0.4s;

            &:hover {
                background-color: $whiteColor;
                color: $primaryColor;
            }
        }
    }
    .callAboutImg {
        width: 40%;
        img {
            width: 100%;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.75);
            border-radius: 10px;
        }
    }
}

.contactForm {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        justify-content: space-around;
    }
    .contactEnd {
        width: 50%;
        align-self: flex-start;

        h2 {
            font-size: 2.5rem;
            color: $primaryText;
            font-weight: 400;
        }
        p {
            font-size: 1rem;
            font-weight: 300;
            color: $secondText;
            margin-top: 15px;
            span {
                font-weight: 500;
            }
        }
    }
    .imageContact {
        width: 350px;
        padding-right: 5px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .form {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #ccc;
        form {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            width: 90%;

            input[type="text"],
            input[type="email"] {
                height: 35px;
                margin: 5px 0;
                padding: 10px;
                border-radius: 10px;
                border: 1px solid #ccc;
                color: $primaryText;
            }
            textarea {
                height: 100px;
                resize: vertical;
                border-radius: 10px;
                border: 1px solid #ccc;
                padding: 10px;
                color: $primaryText;
            }
            button {
                width: 120px;
                height: 35px;
                font-size: 1rem;
                color: $whiteColor;
                background-color: $primaryColor;
                border: 1px solid $primaryColor;
                border-radius: 10px;
                margin-top: 10px;
                font-weight: 400;
                cursor: pointer;
                transition: all ease 0.4s;
                &:hover {
                    background-color: $whiteColor;
                    color: $primaryColor;
                }
            }
        }
        .alerts {
            background-color: $alertColor;
            margin: 5px 0;
            padding: 5px 0;
            border-radius: 10px;
            color: $whiteColor;
            h3 {
                padding-left: 10px;
            }
            ul {
                li {
                    padding: 5px 10px;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .banner {
        margin-top: 50px;

        .container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .bannerCall {
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            h2 {
                text-align: center;
                font-size: 2rem;
            }
        }
        .bannerImg {
            width: 80%;
            margin-top: 10px;
        }
    }

    .features {
        h2 {
            font-size: 2rem;
            text-align: center;
        }

        .featuresCall {
            display: flex;
            align-items: center;
            flex-direction: column;

            .featuresImg {
                width: 70%;
            }

            .featuresCards {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .featuresCardWrapper {
                    width: 100%;

                    .cardWrapperIcon {
                        .material-icons {
                            font-size: 1.5rem;
                        }
                    }
                    .cardWrapperTitle {
                        font-size: 1.2rem;
                    }
                    .cardWrapperDesc {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .callAbout {
        background-image: url("../img/image-alt2.png");
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;

        .container {
            justify-content: center;
            flex-direction: column;
        }
        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
            left: 0;
            right: 0;
            z-index: 1;
        }

        .callAboutText {
            width: 80%;
            align-items: center;
            padding-right: 0;
            margin: 0 auto;
            z-index: 2;

            h2 {
                font-size: 2rem;
                color: $whiteColor;
                text-shadow: 1px 1px 2px black;
            }
            p {
                color: $whiteColor;
                text-shadow: 1px 1px 2px black;
            }
        }
        .callAboutImg {
            display: none;
        }
    }

    .contactForm {
        .container {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        .form {
            width: 80%;
            border-left: 0;
            margin-top: 10px;
        }
        .contactEnd {
            width: 100%;
            text-align: center;

            h2 {
                font-size: 2rem;
                color: $primaryText;
                font-weight: 400;
            }
            p {
                font-size: 1rem;
                font-weight: 300;
                color: $secondText;
                margin-top: 15px;
                span {
                    font-weight: 500;
                }
            }
        }
    }
}
