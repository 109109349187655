@import 'colors.scss';

.footer {
   padding: 20px 0;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: $primaryColor;
   color: $whiteColor;
   .container {
      justify-content: center;
   }
   .footer1 {
      font-size: 0.8rem;
   }
}
